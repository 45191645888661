<template>
  <modal-wrapper :id="id" size="md">
    <template #title>
      Modifica stato
    </template>
    <template #body>
      <div>
        <p>
          Si sta per modificare lo stato in <strong>{{label}}</strong>.
          <br />
          Procedere?
        </p>
        <p class="form-label mb-2 mt-3">Note</p>
        <b-form-textarea
          id="notes"
          v-model="statusNote"
          placeholder=""
          rows="6"
          max-rows="6"
        ></b-form-textarea>
      </div>
    </template>
    <template #footer>
      <div class="d-flex w-100">
        <b-button
          @click="closeModal"
          variant="primary"
          class="me-auto"
          size="sm"
        >
          Chiudi
        </b-button>
        <b-button
          @click="changeStatus"
          variant="success"
          size="sm"
        >
          Conferma
        </b-button>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalStatusChange',
  components: { ModalWrapper },
  props: {
    id: String,
    status: String,
    label: String,
    action: Function,
  },
  data() {
    return {
      statusNote: null,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    changeStatus() {
      this.action(this.statusNote);
    },
  },
};
</script>

<style scoped>

</style>
